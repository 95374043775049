import CustomAccordion from "./CustomAccordion";
import { Stack, Image } from "@mantine/core";
import UnStyledTextLink from "@/components/button/UnstyledTextLink";
import UnStyledButton from "@/components/button/UnstyledButton";
import ChangePasswordForm from "@/components/profile/ChangePasswordForm";
import {
  openDeleteAccountConfirmModal,
  openDeleteAccountPermanentlyModal,
  openFormModal,
  openSuccessModal,
} from "@/components/common/modals";
import {
  DeleteAccountConfirmModal,
  DeleteAccountUsingTextInputModal,
  DeleteAccountUsingPasswordModal,
} from "@/components/profile/DeleteAccountModals";
import { closeAllModals } from "@mantine/modals";
import { useApi } from "@/hooks/useApi";
import { useRouter } from "next/router";
import {
  changePasswordModalId,
  deleteModalId,
  setPasswordModalId,
  deleteAccountConfirmModalId,
} from "@/data/modal";
import SetPasswordForm from "@/components/profile/SetPasswordForm";
import { getQueryClient } from "@/api";
import { contract } from "contract";
import ErrorMessage from "@/components/ErrorMessage";
import CustomErrorMessage from "@/components/CustomErrorMessage";
import { UserDataType } from "@/types/auth";
import Loading from "@/components/common/Loader";
import { useAccessRestriction } from "@/hooks/useAccessRestriction";
import { useLogin } from "@/contexts/LoginProvider";
import { useDisclosure } from "@mantine/hooks";
import {
  ActionEnum,
  PageEnum,
  SectionEnum,
  canPerformAction,
} from "@/data/pageRestriction";
import { openInviteCollaborator } from "@/components/collaborator/inviteCollaboratorModal";
import isNil from "lodash/isNil";
import { getRoleFromUserDetails } from "@/root/utils";
import { EventOrWeddingPathChosen } from "contract/enum";

const ProfileNavbarAccordion = ({
  onCloseDrawer,
  userDetails,
}: {
  onCloseDrawer: () => void;
  userDetails: UserDataType;
}) => {
  const { makeApiCall } = useApi();
  const router = useRouter();
  const { refreshLoginState } = useLogin();

  const [inviteCollaboratorOpened, inviteCollaboratorModalHandler] =
    useDisclosure();

  const handleChangePassword = () => {
    openFormModal({
      modalId: changePasswordModalId,
      header: "Change Password",
      formComponent: <ChangePasswordForm />,
    });
  };

  const handleSetPassword = () => {
    openFormModal({
      modalId: setPasswordModalId,
      header: "Set Password",
      formComponent: <SetPasswordForm />,
    });
  };

  const handleDeleteConfirmation = () => {
    openDeleteAccountConfirmModal({
      modalId: deleteAccountConfirmModalId,
      header: "",
      formComponent: (
        <DeleteAccountConfirmModal
          userDetails={userDetails}
          handleContinue={openDeleteAccountModal}
          handleCancel={() => {
            closeAllModals();
          }}
        />
      ),
    });
  };

  const openDeleteAccountModal = () => {
    const isPasswordSet = userDetails.isPasswordSet;
    closeAllModals();
    openDeleteAccountPermanentlyModal({
      modalId: deleteModalId,
      header: "Delete Account",
      formComponent: isPasswordSet ? (
        <DeleteAccountUsingPasswordModal handleDelete={deleteAccount} />
      ) : (
        <DeleteAccountUsingTextInputModal handleDelete={deleteAccount} />
      ),
    });
  };
  const deleteAccount = (currentPassword?: string): void => {
    makeApiCall({
      fetcherFn: async () => {
        const response = await getQueryClient().user.deleteUser.mutation({
          body: {
            confirmPassword: currentPassword,
          },
        });
        return response;
      },
      onSuccessFn: () => {
        router.push("/").then(() => {
          refreshLoginState();
          openSuccessModal({
            header: "Deleted Successfully",
            message: "Your account has been deleted successfully.",
            icon: (
              <Image
                src={"/images/success-circle.svg"}
                alt="Deleted Successfully"
                w="100px"
                display={"inline-block"}
              />
            ),
          });
        });
      },
      showLoader: true,
    });
  };

  useAccessRestriction({ userDetails: userDetails, isLoggedIn: true });
  return (
    <CustomAccordion accordionControlLabel="Profile">
      <Stack pl={0}>
        {isNil(userDetails.vendorType) && (
          <UnStyledTextLink
            href="/profile"
            label="View Profile"
            c="#f8f8f8"
            fw={500}
          />
        )}
        {canPerformAction({
          role: getRoleFromUserDetails(userDetails),
          pageOrSection: PageEnum.timeline,
          action: ActionEnum.invite,
        }) &&
          userDetails.eventOrWeddingPathChosen &&
          userDetails.eventOrWeddingPathChosen ===
            EventOrWeddingPathChosen.weddingPath && (
            <UnStyledButton
              label="Invite Collaborators"
              onClick={() => {
                openInviteCollaborator({
                  inviteCollaboratorOpened,
                  inviteCollaboratorModalClose:
                    inviteCollaboratorModalHandler.close,
                });
                onCloseDrawer();
              }}
              c="#f8f8f8"
              fw={500}
            />
          )}
        {userDetails.isPasswordSet ? (
          <UnStyledButton
            label="Change Password"
            onClick={() => {
              handleChangePassword();
              onCloseDrawer();
            }}
            c="#f8f8f8"
            fw={500}
          />
        ) : (
          <UnStyledButton
            label="Set Password"
            onClick={() => {
              handleSetPassword();
              onCloseDrawer();
            }}
            c="#f8f8f8"
            fw={500}
          />
        )}
        {canPerformAction({
          role: getRoleFromUserDetails(userDetails),
          pageOrSection: SectionEnum.commonHeader,
          action: ActionEnum.delete,
        }) && (
          <UnStyledButton
            label="Delete Account"
            onClick={() => {
              handleDeleteConfirmation();
              onCloseDrawer();
            }}
            c="#f8f8f8"
            fw={500}
          />
        )}
      </Stack>
    </CustomAccordion>
  );
};

export const ProfileNavbarAccordionWithLoading = ({
  onCloseDrawer,
}: {
  onCloseDrawer: () => void;
}) => {
  const { data, isLoading, error } = getQueryClient().user.getProfile.useQuery(
    [contract.user.getProfile.path],
    {}
  );
  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <ErrorMessage error={error} h="100%" />;
  }
  if (data?.status !== 200) {
    return <CustomErrorMessage errorMessage="User data not available" />;
  }
  return (
    <ProfileNavbarAccordion
      userDetails={data.body}
      onCloseDrawer={onCloseDrawer}
    />
  );
};

export default ProfileNavbarAccordion;
