import { Box, Button, Center, BackgroundImage } from "@mantine/core";
import { useHover } from "@mantine/hooks";
import Link from "next/link";
import SectionTitle from "@/components/textComponent/title/SectionTitle";

export const WeddingDateSetHome = () => {
  const { hovered, ref } = useHover();

  return (
    <Box
      ref={ref}
      w="70%"
      pos="absolute"
      h="100%"
      bgsz="cover"
      bgr="no-repeat"
      style={{
        background: "url('/images/left.webp')",
        left: "0%",
        top: "0%",
        clipPath: "polygon(0 100%, 65% 100%, 90% 0%, 0 0)",
        zIndex: "2",
      }}
    >
      {hovered && (
        <Box
          bg="#1B9AAA"
          w="100%"
          mih="100%"
          style={{ zIndex: 2, opacity: 0.5 }}
        />
      )}

      {hovered && (
        <Box pos="absolute" w="60%" top="30%" left="10%">
          <SectionTitle c="#FFFFFF" ta="center" fz={{ base: 32, sm: 40 }}>
            I know my <br /> wedding dates
          </SectionTitle>

          <Center py="18">
            <Link href="/register">
              <Button
                variant="filled"
                color="#F28482"
                fw="700"
                ta="center"
                radius="8px"
                px="100px"
                fz="20px"
                size="lg"
                ff="Inter"
                mx="auto"
              >
                CONTINUE
              </Button>
            </Link>
          </Center>
        </Box>
      )}
    </Box>
  );
};

export const WeddingDateSetHomeMobile = () => {
  return (
    <BackgroundImage
      w="100%"
      h={{ base: "58vh", md: "100%", lg: "100%" }}
      src="/images/left.webp"
    >
      <Box
        bg="#1B9AAA"
        w="100%"
        mih="100%"
        style={{ zIndex: 1, opacity: 0.5 }}
      />
      <Box
        w="100%"
        pos="absolute"
        top={{ base: "15%", xs: "16%", sm: "" }}
        left="50%"
        style={{ transform: "translateX(-50%)", zIndex: 2 }}
      >
        <SectionTitle c="#FFFFFF" ta="center">
          I know my <br />
          wedding dates
        </SectionTitle>

        <Center mt="1.0rem" py="18" w="100%">
          <Link href="/register">
            <Button
              variant="filled"
              color="#F28482"
              fw="600"
              w="250"
              ta="center"
              radius="8px"
              px={{ base: "60px", md: "100px", lg: "100px" }}
              fz={{ base: "20px", md: "30px", lg: "30px" }}
              size="md"
              ff="Inter"
              mx="auto"
            >
              CONTINUE
            </Button>
          </Link>
        </Center>
      </Box>
    </BackgroundImage>
  );
};
