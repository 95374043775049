import { useUserData } from "@/contexts/UserProvider";
import { Avatar, Group, Menu, Stack, Image, Text } from "@mantine/core";
import Link from "next/link";
import {
  openDeleteAccountConfirmModal,
  openDeleteAccountPermanentlyModal,
  openFormModal,
  openSuccessModal,
} from "./modals";
import {
  DeleteAccountConfirmModal,
  DeleteAccountUsingTextInputModal,
  DeleteAccountUsingPasswordModal,
} from "@/components/profile/DeleteAccountModals";
import { getQueryClient } from "@/api";
import { useApi } from "@/hooks/useApi";
import { useRouter } from "next/router";
import ChangePasswordForm from "@/components/profile/ChangePasswordForm";
import { ThemeVariant } from "@/types/navigation";
import { IconCaretUpFilled } from "@tabler/icons-react";
import { useLogin } from "@/contexts/LoginProvider";
import {
  changePasswordModalId,
  deleteAccountConfirmModalId,
  deleteModalId,
  setPasswordModalId,
} from "@/data/modal";
import { UserDataType } from "@/types/auth";
import SetPasswordForm from "@/components/profile/SetPasswordForm";
import { useAccessRestriction } from "@/hooks/useAccessRestriction";
import { getInitialsForAvatar, getRoleFromUserDetails } from "@/root/utils";
import { closeAllModals } from "@mantine/modals";
import { useDisclosure } from "@mantine/hooks";
import {
  ActionEnum,
  PageEnum,
  SectionEnum,
  canPerformAction,
} from "@/data/pageRestriction";
import { openInviteCollaborator } from "@/components/collaborator/inviteCollaboratorModal";
import isNil from "lodash/isNil";
import { EventOrWeddingPathChosen } from "contract/enum";

const ProfileName = ({ variant }: { variant: ThemeVariant }) => {
  const textColor = variant === ThemeVariant.LIGHT ? "white" : "black";
  const subTextColor =
    variant === ThemeVariant.LIGHT ? "white" : { md: "white", lg: "#999999" };
  const { userDetails } = useUserData();
  return (
    <Stack gap="0px">
      {userDetails && (
        <Text
          c={textColor}
          ff="Inter"
          style={{ whiteSpace: "nowrap", textOverflow: "ellipsis" }}
          tt="capitalize"
          fw="600"
          fz="16px"
          maw={120}
          truncate
        >
          Me
        </Text>
      )}
      <Text
        c={subTextColor}
        tt="capitalize"
        lh="1.01"
        ff="Inter"
        fw="600"
        fz="12px"
      >
        profile
      </Text>
    </Stack>
  );
};

const ProfileHeader = ({
  isLoggedIn,
  userDetails,
  themeVariant = ThemeVariant.DARK,
  onLogout,
}: {
  isLoggedIn: boolean;
  userDetails: UserDataType;
  themeVariant?: ThemeVariant;
  onLogout: () => void;
}) => {
  const { makeApiCall } = useApi();
  const router = useRouter();
  const { refreshLoginState } = useLogin();

  const [inviteCollaboratorOpened, inviteCollaboratorModalHandler] =
    useDisclosure();

  const handleChangePassword = () => {
    openFormModal({
      modalId: changePasswordModalId,
      header: "Change Password",
      formComponent: <ChangePasswordForm />,
    });
  };

  const handleSetPassword = () => {
    openFormModal({
      modalId: setPasswordModalId,
      header: "Set Password",
      formComponent: <SetPasswordForm />,
    });
  };

  const handleDeleteConfirmation = () => {
    openDeleteAccountConfirmModal({
      modalId: deleteAccountConfirmModalId,
      header: "",
      formComponent: (
        <DeleteAccountConfirmModal
          userDetails={userDetails}
          handleContinue={openDeleteAccountModal}
          handleCancel={() => {
            closeAllModals();
          }}
        />
      ),
    });
  };

  const openDeleteAccountModal = () => {
    const isPasswordSet = userDetails.isPasswordSet;
    closeAllModals();
    openDeleteAccountPermanentlyModal({
      modalId: deleteModalId,
      header: "Delete Account",
      formComponent: isPasswordSet ? (
        <DeleteAccountUsingPasswordModal handleDelete={deleteAccount} />
      ) : (
        <DeleteAccountUsingTextInputModal handleDelete={deleteAccount} />
      ),
    });
  };

  const deleteAccount = (currentPassword?: string): void => {
    makeApiCall({
      fetcherFn: async () => {
        const response = await getQueryClient().user.deleteUser.mutation({
          body: {
            confirmPassword: currentPassword,
          },
        });
        return response;
      },
      onSuccessFn: () => {
        router.push("/").then(() => {
          refreshLoginState();
          openSuccessModal({
            header: "Deleted Successfully",
            message: "Your account has been deleted successfully.",
            icon: (
              <Image
                src={"/images/success-circle.svg"}
                alt="Deleted Successfully"
                w="100px"
                display={"inline-block"}
              />
            ),
          });
        });
      },
      showLoader: true,
    });
  };

  useAccessRestriction({ userDetails: userDetails, isLoggedIn: isLoggedIn });

  return (
    <>
      {isLoggedIn ? (
        <Menu
          trigger="hover"
          styles={{
            dropdown: {
              border: "1px solid #ADADAD",
              borderRadius: "12px",
            },
            divider: {
              backgroundColor: "#ADADAD",
              height: "1px",
            },
          }}
        >
          <Menu.Target>
            <Group gap="sm" justify="center">
              {userDetails && (
                <Avatar
                  style={{ objectFit: "cover" }}
                  color={
                    themeVariant === ThemeVariant.LIGHT ? "white" : "black"
                  }
                  src={userDetails.profilePhotoUrl}
                  size="35px"
                >
                  {getInitialsForAvatar(
                    `${userDetails.firstName} ${userDetails.lastName}`
                  )}
                </Avatar>
              )}
              <ProfileName variant={themeVariant} />
            </Group>
          </Menu.Target>
          <Menu.Dropdown c="#3F3F3F" fw={400} lh="1.21" fz={12}>
            <Menu.Label>
              <Group justify="space-between">
                <Text lh="1.21" fz={14} fw={600} c="#3F3F3F">
                  Profile
                </Text>
                <IconCaretUpFilled size={16} color="black" />
              </Group>
            </Menu.Label>
            {isNil(userDetails.vendorType) && (
              <Link href="/profile">
                <Menu.Item>View Profile</Menu.Item>
              </Link>
            )}
            {canPerformAction({
              role: getRoleFromUserDetails(userDetails),
              pageOrSection: PageEnum.timeline,
              action: ActionEnum.invite,
            }) &&
              userDetails.eventOrWeddingPathChosen &&
              userDetails.eventOrWeddingPathChosen ===
                EventOrWeddingPathChosen.weddingPath && (
                <Menu.Item
                  onClick={() => {
                    openInviteCollaborator({
                      inviteCollaboratorOpened,
                      inviteCollaboratorModalClose:
                        inviteCollaboratorModalHandler.close,
                    });
                  }}
                >
                  Invite Collaborators
                </Menu.Item>
              )}

            {userDetails.isPasswordSet ? (
              <Menu.Item onClick={handleChangePassword}>
                Change Password
              </Menu.Item>
            ) : (
              <Menu.Item onClick={handleSetPassword}>Set Password</Menu.Item>
            )}
            {canPerformAction({
              role: getRoleFromUserDetails(userDetails),
              pageOrSection: SectionEnum.commonHeader,
              action: ActionEnum.delete,
            }) && (
              <>
                <Menu.Item onClick={handleDeleteConfirmation}>
                  Delete Account
                </Menu.Item>
                <Menu.Divider />
              </>
            )}
            <Menu.Item onClick={onLogout}>Log out</Menu.Item>
          </Menu.Dropdown>
        </Menu>
      ) : (
        <Menu
          trigger="hover"
          styles={{
            dropdown: {
              border: "1px solid #ADADAD",
              borderRadius: "12px",
            },
            divider: {
              backgroundColor: "#ADADAD",
              height: "1px",
            },
          }}
        >
          <Menu.Target>
            <Text lh="1.21" fz={14} fw={600} c="#3F3F3F">
              Login
            </Text>
          </Menu.Target>
          <Menu.Dropdown c="#3F3F3F" fw={400} lh="1.21" fz={12}>
            <Link href="/login">
              <Menu.Item>User Login</Menu.Item>
            </Link>
            <Link href="/login/wedding-planner">
              <Menu.Item>Wedding Planner Login</Menu.Item>
            </Link>
            <Link href="/login/vendor">
              <Menu.Item>Vendor Login</Menu.Item>
            </Link>
            <Menu.Divider />
          </Menu.Dropdown>
        </Menu>
      )}
    </>
  );
};

export default ProfileHeader;
