import {
  PasswordInput,
  PasswordInputProps,
  InputBaseProps,
  InputVariant,
  StylesApiProps,
  __InputStylesNames,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import {
  Controller,
  Path,
  UseFormReturn,
  RegisterOptions,
  FieldValues,
} from "react-hook-form";
import get from "lodash/get";
import { titleCase } from "@/utils/titleCase";
import { IconEyeOff, IconEye } from "@tabler/icons-react";

function CustomPasswordInputField<T extends FieldValues>({
  hform,
  name,
  rules,
  inputStyles,
  placeholder,
  ...props
}: {
  hform: UseFormReturn<T>;
  name: Path<T>;
  placeholder?: string;
  rules?: RegisterOptions;
  inputStyles?: StylesApiProps<{
    props: InputBaseProps;
    defaultRef: HTMLInputElement;
    defaultComponent: "input";
    stylesNames: __InputStylesNames;
    variant: InputVariant;
  }>;
} & PasswordInputProps) {
  const {
    control,
    formState: { errors },
  } = hform;
  const isTablet = useMediaQuery("(max-width:1024px");
  const error = get(errors, name);
  const isMobile = useMediaQuery("(max-width:380px");

  return (
    <Controller
      control={control}
      name={name}
      rules={rules as RegisterOptions<T, Path<T>>}
      render={({
        field: { onChange, onBlur, value: fieldValue, name, ref },
      }) => (
        <PasswordInput
          ref={ref}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          value={fieldValue}
          placeholder={titleCase(placeholder ? placeholder : "")}
          styles={{
            innerInput: {
              fontFamily: "Inter",
              paddingInline: 20,
              zIndex: 0,
            },
            visibilityToggle: {
              zIndex: 0,
              right: "40%",
            },
            label: {
              color: "white",
              fontWeight: 700,
              fontSize: 17,
              paddingBottom: 10,
            },
            input: {
              color: "black",
              background: "white",
              borderRadius: 100,
              fontSize: isMobile || isTablet ? 14 : 16,
              fontFamily: "Inter",
              border: "0.5px solid #000",
              height: "50px",
              zIndex: 0,
            },
            section: { zIndex: 0 },
            error: {
              fontSize: 12,
              paddingLeft: 10,
              paddingTop: 5,
              fontFamily: "Inter",
            },
            ...inputStyles?.styles,
          }}
          error={error && error.message ? String(error.message) : ""}
          visibilityToggleIcon={({ reveal }) =>
            reveal ? (
              <IconEye
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
            ) : (
              <IconEyeOff
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
            )
          }
          {...props}
        />
      )}
    />
  );
}

export default CustomPasswordInputField;
