import { Box, Stack } from "@mantine/core";
import SectionTitle from "@/components/textComponent/title/SectionTitle";
import CustomParagraph from "@/components/textComponent/CustomParagraph";
import SubSectionTitle from "@/components/textComponent/title/SubSectionTitle";

export const BottomHeadlineTextDesktopView = () => {
  return (
    <Box
      pos="absolute"
      w="100%"
      bottom={0}
      bg={"#FFFFFFCC"}
      style={{ zIndex: 5 }}
    >
      <Stack py={{ base: "1em", lg: "2em" }} gap={"1.5em"} px={0}>
        <SectionTitle ta={"center"} fz={{ base: 24, sm: 36 }}>
          Your wedding planning journey
        </SectionTitle>
        <CustomParagraph
          ta={"center"}
          m="0 auto"
          maw={1026}
          fz={{ base: 18, sm: 24 }}
        >
          from the moment you say &apos;yes&apos; to the honeymoon you&apos;ve
          dreamed of - should be joyful, romantic, and stress-free. Let Uthsav
          make that possible.
        </CustomParagraph>
      </Stack>
    </Box>
  );
};

export const BottomHeadlineTextMobileView = () => {
  return (
    <Box w="100%" bg={"#FFFFFFCC"} style={{ zIndex: 5 }} py={50}>
      <Stack gap={"1.5em"} px="xs">
        <SubSectionTitle ta={"center"}>
          Your wedding planning journey
        </SubSectionTitle>
        <CustomParagraph ta={"center"} m="0 auto" maw={"65rem"}>
          from the moment you say &apos;yes&apos; to the honeymoon you&apos;ve
          dreamed of - should be joyful, romantic, and stress-free. Let Uthsav
          make that possible.&rdquo;
        </CustomParagraph>
      </Stack>
    </Box>
  );
};
