import { Button, Box, Center, BackgroundImage } from "@mantine/core";
import { useHover } from "@mantine/hooks";
import Link from "next/link";
import SectionTitle from "@/components/textComponent/title/SectionTitle";

export const WeddingDateNotSetHome = () => {
  const { hovered, ref } = useHover();
  return (
    <Box
      w="70%"
      ref={ref}
      pos="absolute"
      h="100%"
      bgr="no-repeat"
      bgsz="cover"
      right="0%"
      style={{ background: "url('/images/right.webp')" }}
    >
      {hovered && (
        <>
          <Box bg="#EF476F" mih="100%" style={{ zIndex: 2, opacity: 0.5 }} />

          <Box w="65%" ta="center" pos="absolute" top="30%" right="0">
            <SectionTitle c="#FFFFFF" px="50" fz={{ base: 32, sm: 40 }}>
              I don&apos;t know my <br /> wedding dates
            </SectionTitle>
            <Center py="18" w="100%">
              <Link href="/getting-started-with-your-wedding">
                <Button
                  variant="filled"
                  color="#84A59D"
                  fw="700"
                  ta="center"
                  radius="8px"
                  px="100px"
                  fz="20px"
                  ff="Inter"
                  size="lg"
                >
                  CONTINUE
                </Button>
              </Link>
            </Center>
          </Box>
        </>
      )}
    </Box>
  );
};

export const WeddingDateNotSetHomeMobile = () => {
  return (
    <BackgroundImage
      w="100%"
      h="42vh"
      src="/images/right.webp"
      style={{
        overflow: "scroll",
      }}
    >
      <Box bg="#EF476F" w="100%" h="100%" style={{ zIndex: 1, opacity: 0.5 }} />
      <Box
        pos="absolute"
        w="100%"
        px="20px"
        top={{ base: "54%", xs: "56%", sm: "58%" }}
        left="50%"
        style={{ zIndex: 3, transform: "translate(-50%, -50%)" }}
      >
        <SectionTitle c="#FFFFFF" ta="center">
          I don&apos;t know my <br /> wedding dates
        </SectionTitle>

        <Center mt="1.0rem" py="18" w="100%">
          <Link href="/getting-started-with-your-wedding">
            <Button
              variant="filled"
              color="#84A59D"
              w={250}
              fw="600"
              ta="center"
              radius="8px"
              px={{ base: "60px", sm: "60px", md: "100px", lg: "100px" }}
              fz={{ base: "20px", sm: "20px", md: "30px", lg: "30px" }}
              size="md"
              ff="Inter"
            >
              CONTINUE
            </Button>
          </Link>
        </Center>
      </Box>
    </BackgroundImage>
  );
};
