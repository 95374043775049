import HeroSubTitle from "@/components/textComponent/HeroSubTitle";
import MetaText from "@/components/textComponent/MetaText";
import { GetBlogsResponseType } from "@/types/blog";
import { Stack, Image, Box, Flex } from "@mantine/core";
import { constructBlogCategoriesId, formatBlogDate } from "@/root/utils";
import CustomParagraph from "@/components/textComponent/CustomParagraph";
import { IconCircle } from "@tabler/icons-react";
import HeroTitle from "@/components/textComponent/title/HeroTitle";
import Link from "next/link";
import CustomNextImage from "@/components/CustomNextImage";

const HeroSection = ({
  latestBlogData,
}: {
  latestBlogData: GetBlogsResponseType;
}) => {
  return (
    <Flex
      justify={"center"}
      align={"center"}
      direction={"column"}
      pos="relative"
      dir="column"
      px={{ base: 30, sm: 60, lg: 80 }}
      py={{ base: 36, sm: 60, lg: 80 }}
      style={{ overflow: "hidden" }}
      bg="primaryPink.1"
      w="100%"
    >
      <Image
        src="/images/background/bgLogoLeftSection.svg"
        pos="absolute"
        top={{ base: "-2%", sm: "-5%", lg: "-10%" }}
        left={{ base: "-50%", sm: "-30%", lg: "-28%" }}
        w={{ base: 520, sm: 700, lg: 916 }}
        h={{ base: 280, sm: 380, lg: 488 }}
        style={{ zIndex: 1 }}
        alt="bg logo"
      />
      <Stack maw={1124} gap={0} align="center">
        <HeroTitle fw={600} c={"#242430"}>
          Our Blogs
        </HeroTitle>
        <CustomParagraph fz={{ base: 18, sm: 24 }} c={"#242430"} ta="center">
          The hottest wedding trends, advice from the pros, and more tips and
          tricks to help you plan the most memorable wedding you could have
          imagined!
        </CustomParagraph>
      </Stack>
      <Link
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
        href={`/blog/${constructBlogCategoriesId(latestBlogData.blogCategoryCollection)}/${latestBlogData.slugId}`}
      >
        <Stack w={"100%"} maw={1124} mt={72} align="center" gap={30}>
          <CustomNextImage
            imageSrc={latestBlogData.imageFileURL}
            imageResponsiveSize="(max-width: 600px) 100vw, (max-width: 800px) 50vw, 300px"
            imageAlt="blog image"
            imageWrapperProps={{
              h: { base: 375, lg: 405 },
              w: "100%",
              style: { zIndex: 2, overflow: "hidden" },
            }}
          />
          <Box>
            <HeroSubTitle ta="center">{latestBlogData.title}</HeroSubTitle>
            <MetaText
              display={"flex"}
              style={{
                alignItems: "center",
                justifyContent: "center",
                gap: 8,
              }}
              mt={10}
            >
              {`${latestBlogData.minutesRead} mins`}

              <IconCircle fill="black" size={5} />
              {`${formatBlogDate(latestBlogData.createdAt)}`}
            </MetaText>
          </Box>
        </Stack>
      </Link>
      <Image
        pos="absolute"
        bottom={{ base: "-12%", sm: "-10%", lg: "-10%" }}
        right={{ base: "-70%", sm: "-20%", lg: "-28%" }}
        style={{ zIndex: 1 }}
        src="/images/background/bgLogoRightSection.svg"
        w={{ base: 520, sm: 700, lg: 916 }}
        h={{ base: 280, sm: 380, lg: 488 }}
        alt="bg logo"
      />
    </Flex>
  );
};
export default HeroSection;
